.Reasons {
    padding: 0 2rem;
    display: flex;
    grid-template: 2rem;
    gap: 2rem
}

.left-r {
    display: grid;
    grid-gap: 20px;
}

.left-r > img {
    object-fit: cover;
}


.left-r>img:nth-of-type(1) {
    width: 12rem;
    height: 28.5rem;
    grid-row: 1 / 3;
}

.left-r>img:nth-of-type(2) {
    width: auto;
    height: 16rem;
    grid-row: 1;
    grid-column: 2 / 4;
}

.left-r>img:nth-of-type(3) {
    width: 14rem;
    grid-row: 2;
    grid-column: 2 / 3;
}

.left-r>img:nth-of-type(4) {
    width: 10rem;
    height: 11.25rem;
    grid-row: 2;
    grid-column: 3 / 4;
}

.right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;

}

.right-r>span {
    font-weight: bold;
    color: var(--orange);
}

.right-r>div {
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img {
    width: 2rem;
    height: 2rem;
}

.partners {
    display: flex;
    gap: 1rem;
}

.partners>img {
    width: 2.5rem;
}

@media screen and (max-width: 768px) {
    .Reasons {
        flex-direction: column;
    }

    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-r > :nth-child(1) {
        width: 7rem;
        height: 17rem;
    }

    .left-r > :nth-child(2) {
        width: 15rem;
        height: 10rem;
    }
}